@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url(./../assets/fonts/montserrat-regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url(./../assets/fonts/montserrat-italic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url(./../assets/fonts/montserrat-medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url(./../assets/fonts/montserrat-semibold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url(./../assets/fonts/montserrat-bold.ttf) format("truetype");
}

:root {
  --nav-bar-height: 130px;
  --tab-bar-height: 72px;
  --max-ui-width: 1000px;
  --icon-size: 24px;
  --font-size: 16px;
  --border-radius-small: 5px;
  --border-radius-medium: 8px;
  --border-radius-large: 11px;
  --border-radius-round: 50%;
  --spacing-vertical-small: 18px;
  --spacing-horizontal-small: 18px;
  --spacing-vertical-regular: 21px;
  --spacing-horizontal-regular: 37px;
  --rgb-color-primair-basis: 194, 194, 194;
  --rgb-color-primair-lighter: 204, 204, 204;
  --rgb-color-primair-shade-1: 127, 127, 127;
  --rgb-color-primair-shade-2: 239, 239, 239;
  --rgb-color-primair-shade-3: 195, 195, 195;
  --rgb-color-primair-shade-4: 147, 147, 147;
  --rgb-color-secundair: 26, 85, 234;
  --rgb-color-secundair-lighter: 75, 123, 227;
  --rgb-color-dangerous: 221, 36, 36;
  --rgb-color-dangerous-lighter: 202, 99, 118;
  --rgb-color-success: 0, 189, 44;
  --rgb-color-success-lighter: 195, 225, 207;
  --rgb-color-gray: 170, 170, 170;
  --rgb-color-gray-lighter: 246, 246, 249;
  --rgb-color-white: 255, 255, 255;
  --rgb-color-black: 0, 0, 0;
  --rgb-color-background: 255, 255, 255;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  color: rgb(var(--rgb-color-black));
  background-color: rgb(var(--rgb-color-background));
}

body * {
  box-sizing: border-box;
}

img {
  color: magenta;
}

.mapboxgl-popup {
  border-radius: 25%;
}

.mapboxgl-popup > .mapboxgl-popup-content {
  padding: 20px 30px !important;
  min-width: 250px;
}

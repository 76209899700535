.rowSubText {
  margin: 0;
  font-weight: 700;
  font-size: 0.75rem;
}

.darkCheckMark {
  width: 12px;
  height: 12px;
  color: rgb(var(--rgb-color-black));
}

.container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2);
  background-color: #e5e5e5;
}

.container.invalidUrl {
  background-color: #aaa;
  background-image: url(../../../assets/graphics/symbols/cross.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 20%;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.container,
.frame {
  border-radius: 20px;
  overflow: hidden;
}
